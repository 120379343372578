import { ContinueButton, ModalTemplate, SoundFx } from "@deliverr/ui-facility";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useSortationModal } from "sortation/components/SortationModal";
import { sortationDangerIconProps } from "sortation/components/SortationModal/sortationModalIconProps";
import { ManifestModal } from "./ManifestModal";

export interface ManifestErrorModalProps {
  title: string;
  text: string;
  hideModalValue: ManifestModal;
}

export const ManifestErrorModal: React.FC<ManifestErrorModalProps> = ({ title, text, hideModalValue }) => {
  const { hideModal } = useSortationModal();
  return (
    <ModalTemplate
      title={<FormattedMessage id="sortation.manifest.errorModalTitle" defaultMessage={title} />}
      text={<FormattedMessage id="sortation.manifest.errorModalText" defaultMessage={text} />}
      iconProps={sortationDangerIconProps}
      soundFx={SoundFx.ERROR_FAST}
    >
      <ContinueButton block onClick={() => hideModal(hideModalValue)} />
    </ModalTemplate>
  );
};
