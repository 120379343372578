import { FC } from "react";
import { OutboundModal } from "./OutboundModal";
import { EmptyGaylordCodeModal } from "./EmptyGaylordCode";
import { InvalidGaylordCodeModal } from "./InvalidGaylordCode";
import { UnrecognizedTrackingCodeModal } from "./UnrecognizedTrackingCode";
import { IncorrectAssignmentModal } from "./IncorrectAssignment";
import { ShippingLabelConfirmation } from "./ShippingLabelConfirmation";
import { GaylordTypeConfirmation } from "sortation/pages/Outbound/modals/GaylordTypeConfirmation";

export const outboundModalMap: Record<OutboundModal, FC<any>> = {
  [OutboundModal.EMPTY_GAYLORD_CODE]: EmptyGaylordCodeModal,
  [OutboundModal.INVALID_GAYLORD_CODE]: InvalidGaylordCodeModal,
  [OutboundModal.UNRECOGNIZED_TRACKING_CODE]: UnrecognizedTrackingCodeModal,
  [OutboundModal.INCORRECT_ASSIGNMENT]: IncorrectAssignmentModal,
  [OutboundModal.REPRINT_SHIPPING_LABEL_CONFIRMATION]: ShippingLabelConfirmation,
  [OutboundModal.GAYLORD_TYPE_CONFIRMATION]: GaylordTypeConfirmation,
};
