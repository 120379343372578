import { DefaultTheme, Title } from "@deliverr/ui";
import { LoadingSpinner } from "@deliverr/ui-facility";
import styled from "@emotion/styled";
import React from "react";
import { ManifestTable } from "./components";
import { useManifests } from "./useManifests";

const Container = styled.div<{}, DefaultTheme>(({ theme }) => `margin: ${theme.spacing.S5};`);

const Card = styled.div<{}, DefaultTheme>(
  ({ theme }) => `
  padding: ${theme.spacing.S5};
  background: ${theme.colors.NEUTRAL["00"]};
  border-radius: ${theme.border.radius.R5};
  box-shadow: ${theme.shadow.S2};
  margin-bottom: ${theme.spacing.S5};
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.S4};
`
);

const StyledContainer = styled(Container)`
  margin-bottom: 30px;
`;

export const Manifests = () => {
  const { manifests, manifestsCurrentlyDownloading, isLoading, downloadManifest, sortationId } = useManifests();

  return (
    <StyledContainer>
      <Card>
        <LoadingSpinner loading={isLoading} text="Loading Manifests">
          <Title> Manifests for {sortationId} </Title>
          <ManifestTable
            manifests={manifests}
            downloadManifest={downloadManifest}
            manifestsCurrentlyDownloading={manifestsCurrentlyDownloading}
          />
        </LoadingSpinner>
      </Card>
    </StyledContainer>
  );
};
