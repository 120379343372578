import { FC } from "react";
import { ManifestErrorModal, ManifestErrorModalProps } from "./ManifestErrorModal";
import { ManifestModal } from "./ManifestModal";

export const manifestModalMap: Record<ManifestModal, FC<any>> = {
  [ManifestModal.FAILED_TO_GET_MANIFEST]: ManifestErrorModal,
  [ManifestModal.UNKNOWN_ERROR]: ManifestErrorModal,
};

export const manifestMessageMap: Record<ManifestModal, ManifestErrorModalProps> = {
  [ManifestModal.FAILED_TO_GET_MANIFEST]: {
    title: "Failed to download manifest",
    text: "Please try again. Contact a supervisor if the issue persists.",
    hideModalValue: ManifestModal.FAILED_TO_GET_MANIFEST,
  },
  [ManifestModal.UNKNOWN_ERROR]: {
    title: "Unknown error",
    text: "An unknown error occurred. Please contact a supervisor.",
    hideModalValue: ManifestModal.UNKNOWN_ERROR,
  },
};
