import { ManifestWithAccountDetails } from "@deliverr/sortation-client";
import { Button, Table, Text } from "@deliverr/ui";
import { format } from "date-fns";
import React from "react";

interface ManifestTableProps {
  manifests: ManifestWithAccountDetails[];
  downloadManifest: (manifest: ManifestWithAccountDetails) => Promise<void>;
  manifestsCurrentlyDownloading: number[];
}

export const ManifestTable: React.FC<ManifestTableProps> = ({
  manifests,
  downloadManifest,
  manifestsCurrentlyDownloading,
}) => {
  return (
    <div>
      <Table
        headings={["ID", "External ID", "Provider", "Warehouse", "External Account", "Generated", "Download"]}
        rows={manifests.map((manifest) => [
          <Text key={manifest.id}>{manifest.id}</Text>,
          <Text key={manifest.id}>{manifest.manifestId}</Text>,
          <Text key={manifest.id}>{manifest.manifestProvider}</Text>,
          <Text key={manifest.id}>{manifest.warehouseId}</Text>,
          <Text key={manifest.id}>{manifest.providerAccountId}</Text>,
          <Text key={manifest.id}>{format(new Date(manifest.createdAt), "MMMM dd, yyyy, h:mmaaa")}</Text>,
          <Button
            key={manifest.id}
            loading={manifestsCurrentlyDownloading.includes(manifest.id)}
            onClick={async () => await downloadManifest(manifest)}
          >
            Download
          </Button>,
        ])}
        caption="Manifests"
      />
    </div>
  );
};
