import { useState } from "react";
import { SortationGaylordError, SortationPackageError, SortationGaylordType } from "@deliverr/sortation-client";
import { SoundFx, createSuccessNotification, logStart, logError } from "@deliverr/ui-facility";
import { useCommonFlow } from "@deliverr/ui-facility/lib-facility/flow/useCommonFlow";
import { useIntl } from "react-intl";
import { useAsyncFn, useUnmount } from "react-use";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { navbarState } from "sortation/base/navbarState";
import { sortationIdState } from "sortation/base/sortationIdState";
import { useSortationClient } from "sortation/base/useSortationClient";
import { useSortationModal } from "sortation/components/SortationModal";
import { isGaylord } from "sortation/utils/stringValidation";
import { parseTrackingInput } from "sortation/utils/parseTrackingInput";
import { TOAST_AUTO_CLOSE } from "sortation/utils/toastAutoClose";
import { TextButton } from "../../components/TextButton";
import { OutboundModal } from "./modals";
import { useSortationFeature } from "sortation/base/SortationFeature";

export const useOutbound = () => {
  const { formatMessage } = useIntl();
  const setNavbar = useSetRecoilState(navbarState);
  const [validGaylord, setValidGaylord] = useState<string | undefined>(undefined);
  const [gaylord, setGaylord] = useState<string | undefined>(undefined);
  const [trackingCode, setTrackingCode] = useState<string | undefined>(undefined);
  const [carrier, setCarrier] = useState<string | undefined>(undefined);
  const [sortCode, setSortCode] = useState<string | undefined>(undefined);
  const [gaylordType, setGaylordType] = useState<SortationGaylordType>();
  const { playSfx, addAutoCloseNotification, resetNotifications, emitFlash } = useCommonFlow();
  const { hideModal, showModal } = useSortationModal();
  const sortationClient = useSortationClient();
  const sortationId = useRecoilValue(sortationIdState);
  const { isGaylordTypeEnabled } = useSortationFeature();

  useUnmount(() => {
    resetNotifications();
    setNavbar({});
  });

  const showInvalidModal = () => {
    emitFlash("DANGER");
    showModal(OutboundModal.INVALID_GAYLORD_CODE, {});
  };

  const resetGaylord = () => {
    setGaylord(undefined);
    setValidGaylord(undefined);
    setCarrier(undefined);
    setGaylordType(undefined);
    setTrackingCode(undefined);
    setSortCode(undefined);
    setNavbar({
      title: formatMessage({ id: "sortation.name.scanGaylord", defaultMessage: "Scan Gaylord" }),
      rightAction: undefined,
    });
  };

  const onModalConfirm = async (scannedGaylord: string, selectedGaylordType: SortationGaylordType) => {
    const ctx = logStart({ fn: "useOutbound.onModalConfirm", scannedGaylord, selectedGaylordType });

    try {
      await handleRegisterGaylordForPackages(scannedGaylord, selectedGaylordType);
    } catch (err) {
      logError(ctx, err);
    } finally {
      hideModal(OutboundModal.GAYLORD_TYPE_CONFIRMATION);
    }
  };

  const [handleRegisterGaylordForPackagesState, handleRegisterGaylordForPackages] = useAsyncFn(
    async (value: string, selectedGaylordType?: SortationGaylordType) => {
      try {
        const registerGaylordRes = await sortationClient.registerGaylordForPackages(value, selectedGaylordType);
        setCarrier(registerGaylordRes.carrier ?? undefined);
        setSortCode(registerGaylordRes.sortCode ?? undefined);
        setValidGaylord(value);
        setGaylordType(registerGaylordRes.gaylordType ?? undefined);
        playSfx(SoundFx.INFO);
        emitFlash("SUCCESS");
        setNavbar({
          title: formatMessage({ id: "sortation.name.loadGaylord", defaultMessage: "Load Gaylord" }),
          rightAction: TextButton({ onClick: resetGaylord, defaultMessage: "change" }),
        });
        addAutoCloseNotification(
          createSuccessNotification(
            formatMessage(
              {
                id: "sortation.successScan",
                defaultMessage: `{code} scanned.`,
              },
              { code: value }
            )
          ),
          TOAST_AUTO_CLOSE
        );
      } catch (err) {
        showInvalidModal();
      }
    }
  );

  const [handleGaylordChangeState, handleGaylordChange] = useAsyncFn(
    async (value: string) => {
      resetNotifications();
      if (!value) {
        emitFlash("DANGER");
        showModal(OutboundModal.EMPTY_GAYLORD_CODE, {});
        return;
      }

      setGaylord(value);

      if (!isGaylord(value)) {
        showInvalidModal();
        return;
      }

      if (!isGaylordTypeEnabled) {
        await handleRegisterGaylordForPackages(value);
        return;
      }

      try {
        const existingGaylord = await sortationClient.getGaylord(value);
        // The gaylord sortCode is missing. This means that a package has not yet been assigned to the gaylord and we want
        // to prompt the associate to specify the gaylord type.
        // This intentionally allows users to update the type if they realize they set the wrong type for a gaylord.
        if (!existingGaylord.sortCode) {
          showModal(OutboundModal.GAYLORD_TYPE_CONFIRMATION, {
            name: value,
            onConfirm: async (sgt: SortationGaylordType) => await onModalConfirm(value, sgt),
          });
        } else {
          await handleRegisterGaylordForPackages(value, existingGaylord.gaylordType ?? undefined);
        }
      } catch (err) {
        showInvalidModal();
        return;
      }
    },
    [sortationId]
  );

  const [handleTrackingCodeScanState, handleTrackingCodeScan] = useAsyncFn(
    async (input: string) => {
      resetNotifications();
      // When in the tracking code mapping flow, the user has the option to change the gaylord by scanning one.
      // if the scan is a gaylord, we'll have to switch over to handleGaylordChange fn.
      if (isGaylord(input)) {
        await handleGaylordChange(input);
        setTrackingCode(undefined);
        return;
      }

      const code = parseTrackingInput(input);

      setTrackingCode(code);
      try {
        const assignPackageRes = await sortationClient.processAssignPackageToGaylordScan(code, validGaylord!);
        setCarrier(assignPackageRes.gaylord.carrier);
        setSortCode(assignPackageRes.gaylord.sortCode ?? undefined);
        playSfx(SoundFx.SUCCESS);
        emitFlash("SUCCESS");
        addAutoCloseNotification(
          createSuccessNotification(
            formatMessage(
              {
                id: "sortation.successScan",
                defaultMessage: `{code} scanned.`,
              },
              { code }
            )
          ),
          TOAST_AUTO_CLOSE
        );
      } catch (err) {
        emitFlash("DANGER");
        if (
          err.code === SortationGaylordError.CARRIER_MISSORT ||
          err.code === SortationPackageError.SORT_CODE_MISSORT
        ) {
          showModal(OutboundModal.INCORRECT_ASSIGNMENT, {});
        } else {
          showModal(OutboundModal.UNRECOGNIZED_TRACKING_CODE, {});
        }
      } finally {
        setTrackingCode("");
      }
    },
    [validGaylord]
  );

  const updateGaylordDetails = async (value) => {
    await handleGaylordChange(value);
  };

  // Determines when to use the tracking code scan or Gaylord scan functions.
  const scanConfig = validGaylord
    ? {
        title: formatMessage(
          {
            id: "sortation.gaylordTrackingScan.title",
            defaultMessage: "Scan tracking code to assign to {gaylord}",
          },
          { gaylord: gaylordType ? gaylordType.toLowerCase() : "gaylord" }
        ),
        label: formatMessage({ id: "sortation.gaylordTrackingScan.label", defaultMessage: "Tracking code" }),
        value: trackingCode,
        onSubmit: handleTrackingCodeScan,
        onChange: setTrackingCode,
        disabled: handleGaylordChangeState.loading || handleRegisterGaylordForPackagesState.loading,
      }
    : {
        title: formatMessage({ id: "sortation.gaylordScan.title", defaultMessage: "Scan gaylord barcode" }),
        label: formatMessage({ id: "sortation.gaylordScan.label", defaultMessage: "Gaylord code" }),
        value: gaylord,
        onSubmit: async (value) => await updateGaylordDetails(value),
        onChange: setGaylord,
        disabled: handleTrackingCodeScanState.loading,
      };

  return {
    carrier,
    validGaylord,
    gaylordType,
    sortCode,
    scanConfig,
    isGaylordTypeEnabled,
  };
};
