import React from "react";
import { DefaultTheme, Title } from "@deliverr/ui";
import styled from "@emotion/styled";
import { FormattedMessage } from "react-intl";
import { CarrierNames } from "sortation/base/carrierNames";
import { isNil } from "lodash/fp";

const Container = styled.div<{ hasCarrierOrSortCode: boolean; hasValue: boolean }, DefaultTheme>(
  ({ hasValue, hasCarrierOrSortCode, theme }) => `
  padding: ${theme.spacing.S6};
  color: ${hasValue ? theme.colors.NEUTRAL["00"] : theme.colors.NEUTRAL[300]};
  /* design todo: fix radius */
  border-radius: 8px 8px 0 0;
  background-color: ${
    hasCarrierOrSortCode ? theme.colors.BLUE[400] : hasValue ? theme.colors.NEUTRAL[400] : theme.colors.NEUTRAL[80]
  };
  position: relative;
`
);

// Unique title size
const Value = styled(Title)`
  font-size: 42px;
  line-height: 54px;
`;

const LargeText = styled.span`
  font-size: 64px;
  line-height: 84px;
`;

const BoldedText = styled.span<{}, DefaultTheme>(
  ({ theme }) => `
  font-weight: ${theme.font.weight.BOLD};
  font-size: ${theme.font.size.F5};
  line-height: 26px;
`
);

interface GaylordTitleProps {
  value?: string;
  carrier?: string;
  sortCode?: string;
  gaylordType?: string;
}

export const GaylordTitle: React.FC<GaylordTitleProps> = ({ value, carrier, sortCode, gaylordType }) => {
  const gaylordIdWithoutSG = value?.substring(2, value.length);
  const carrierName = carrier && CarrierNames[carrier] ? <FormattedMessage {...CarrierNames[carrier]} /> : carrier;
  const hasCarrierOrSortCode = !isNil(carrier) || !isNil(sortCode);
  const formattedGaylordType = gaylordType ? `(${gaylordType})` : "";
  return (
    <Container hasValue={!!value} hasCarrierOrSortCode={hasCarrierOrSortCode} data-testid="gaylord_container">
      {hasCarrierOrSortCode ? (
        <div>
          <LargeText>{sortCode ?? carrierName}</LargeText>
          {sortCode ? <p>{carrierName}</p> : null}
          <p>
            SG {gaylordIdWithoutSG} {formattedGaylordType}
          </p>
        </div>
      ) : value ? (
        <div>
          <p>SG</p>
          <Value as="span">
            {gaylordIdWithoutSG} {formattedGaylordType}
          </Value>
        </div>
      ) : (
        <>
          <BoldedText>
            <FormattedMessage id="sortation.scanGaylord.noneChosen" defaultMessage="No gaylord chosen" />
          </BoldedText>
          <Value>SG–</Value>
        </>
      )}
    </Container>
  );
};
