import { Button, DefaultTheme, Select } from "@deliverr/ui";
import styled from "@emotion/styled";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useGaylordPrint } from "sortation/pages/LabelPrint/GaylordLabel/useGaylordPrint";
import { gaylordLabelOptions } from "./gaylordOptions";

const Card = styled.div<{}, DefaultTheme>(
  ({ theme }) => `
  padding: ${theme.spacing.S5};
  background: ${theme.colors.NEUTRAL["00"]};
  border-radius: ${theme.border.radius.R5};
  box-shadow: ${theme.shadow.S2};
  margin-bottom: ${theme.spacing.S5};
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.S4};
`
);

export const GaylordLabel = () => {
  const {
    selectedNumberOfLabels,
    handleNumberOfLabelsChange,
    createGaylords,
    createGaylordState,
    hasConnectedPrinter,
  } = useGaylordPrint();

  return (
    <>
      <Card>
        <Select
          label="Select number of gaylord labels"
          value={selectedNumberOfLabels}
          options={gaylordLabelOptions}
          onChange={handleNumberOfLabelsChange}
          data-testid="gaylord_select"
        />
      </Card>
      <Button
        disabled={!hasConnectedPrinter}
        loading={createGaylordState.loading}
        block
        onClick={createGaylords}
        data-testid="gaylord_print"
      >
        <FormattedMessage id="sortation.printLabel.gaylord.printButton" defaultMessage="Print label(s)" />
      </Button>
    </>
  );
};
