import { Button, DefaultTheme, Select, ThemeProps } from "@deliverr/ui";
import { ModalTemplate } from "@deliverr/ui-facility";
import styled from "@emotion/styled";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSortationModal } from "sortation/components/SortationModal";
import { OutboundModal } from "./OutboundModal";
import { SortationGaylordType } from "@deliverr/sortation-client";

interface IProps {
  name: string;
  onConfirm: (gaylordType: SortationGaylordType | undefined) => void;
}

const gaylordOptions = [
  { value: SortationGaylordType.GAYLORD, label: "Gaylord" },
  { value: SortationGaylordType.PALLET, label: "Pallet" },
];

const ButtonContainer = styled.div<{}, DefaultTheme>`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing.S3};
`;

const GaylordTypeFormContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${theme.spacing.S2};
  margin-bottom: ${theme.spacing.S3};
`
);

export const GaylordTypeConfirmation: React.FC<IProps> = ({ name, onConfirm }) => {
  const { hideModal } = useSortationModal();
  const [gaylordType, setGaylordType] = useState<SortationGaylordType>();

  const handleGaylordTypeChange = (option) => {
    setGaylordType(option.value);
  };

  return (
    <ModalTemplate
      buttonLayout={"column"}
      title={
        <FormattedMessage
          id="sortation.gaylordScan.gaylordType.confirmationModal.header"
          defaultMessage="Select type for {name}"
          values={{ name }}
        />
      }
    >
      <GaylordTypeFormContainer>
        <Select
          placeholder="Gaylord Type"
          label="Select Gaylord Type"
          value={gaylordOptions.find((opt) => opt.value === gaylordType)}
          options={gaylordOptions}
          onChange={handleGaylordTypeChange}
        />
      </GaylordTypeFormContainer>

      <ButtonContainer>
        <Button block onClick={() => onConfirm(gaylordType)} disabled={!gaylordType}>
          <FormattedMessage id="sortation.gaylordScan.gaylordType.confirmationModal.confirm" defaultMessage="Confirm" />
        </Button>
        <Button block onClick={() => hideModal(OutboundModal.GAYLORD_TYPE_CONFIRMATION)} secondary>
          <FormattedMessage id="sortation.gaylordScan.gaylordType.confirmationModal.cancel" defaultMessage="Cancel" />
        </Button>
      </ButtonContainer>
    </ModalTemplate>
  );
};
