export interface GaylordLabelNumberOption {
  label: string;
  value: number;
}

export const gaylordLabelOptions: GaylordLabelNumberOption[] = [
  { label: "1", value: 1 },
  { label: "5", value: 5 },
  { label: "10", value: 10 },
  { label: "20", value: 20 },
  { label: "30", value: 30 },
  { label: "40", value: 40 },
  { label: "50", value: 50 },
  { label: "60", value: 60 },
  { label: "70", value: 70 },
  { label: "80", value: 80 },
  { label: "90", value: 90 },
  { label: "100", value: 100 },
];
