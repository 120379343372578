import React from "react";
import { FormattedMessage } from "react-intl";
import { ArrivalScan } from "sortation/pages/ArrivalScan";
import { Dashboard } from "sortation/pages/Dashboard";
import { DivertLane } from "sortation/pages/DivertLaneScan";
import { DockDoorScanV2 } from "sortation/pages/DockDoorScanV2";
import { HubAppRedirect } from "sortation/pages/HubAppRedirect";
import { LabelPrint } from "sortation/pages/LabelPrint";
import { DockDoorLabel } from "sortation/pages/LabelPrint/DockDoorLabel";
import { GaylordLabel } from "sortation/pages/LabelPrint/GaylordLabel";
import { ShippingLabel } from "sortation/pages/LabelPrint/ShippingLabel";
import { ShippingLabelDownload } from "sortation/pages/LabelPrint/ShippingLabelDownload";
import { ZebraLabelPrint } from "sortation/pages/LabelPrint/ZebraLabelPrint";
import { LeftInBuildingScan } from "sortation/pages/LeftInBuildingScan";
import { LoadToTrailerScan } from "sortation/pages/LoadToTrailerScan";
import { Manifests } from "sortation/pages/Manifests/Manifests";
import { Outbound } from "sortation/pages/Outbound";
import { UtilityScan } from "sortation/pages/UtilityScan/UtilityScan";
import { SortationPath } from "./sortationPath";

export interface RouteConfig {
  name?: React.ReactNode;
  path: SortationPath;
  page: React.ReactNode;
  previousPage?: SortationPath;
}

export const routes: RouteConfig[] = [
  // Nav Routes
  {
    name: <FormattedMessage id="sortation.name.dashboard" defaultMessage="Sortation Center" />,
    path: SortationPath.DASHBOARD,
    page: <Dashboard />,
  },
  {
    name: <FormattedMessage id="sortation.name.hubAppRedirect" defaultMessage="Hub App Redirect" />,
    path: SortationPath.HUB_APP_REDIRECT,
    page: <HubAppRedirect />,
  },

  // Scan Routes
  {
    name: <FormattedMessage id="sortation.name.arrival" defaultMessage="Inbound Packages" />,
    path: SortationPath.ARRIVAL,
    page: <ArrivalScan />,
  },
  {
    name: <FormattedMessage id="sortation.name.scanGaylord" defaultMessage="Scan Gaylord" />,
    path: SortationPath.OUTBOUND,
    page: <Outbound />,
  },
  {
    name: <FormattedMessage id="sortation.name.divertLaneScan" defaultMessage="Scan Divert Lane" />,
    path: SortationPath.DIVERT_LANE,
    page: <DivertLane />,
  },
  {
    name: <FormattedMessage id="sortation.name.scanDockDoor" defaultMessage="Dock Doors" />,
    path: SortationPath.DOCK_DOOR_SCAN_V2,
    page: <DockDoorScanV2 />,
  },
  {
    name: <FormattedMessage id="sortation.name.utilityScans" defaultMessage="Utility Scans" />,
    path: SortationPath.UTILITY_SCAN,
    page: <UtilityScan />,
  },
  {
    name: <FormattedMessage id="sortation.name.leftInBuildingScan" defaultMessage="Left In Building Scan" />,
    path: SortationPath.LEFT_IN_BUILDING_SCAN,
    page: <LeftInBuildingScan />,
  },
  {
    name: <FormattedMessage id="sortation.name.loadToTrailerScan" defaultMessage="BOL/Trailer Scan" />,
    path: SortationPath.LOAD_TO_TRAILER_SCAN,
    page: <LoadToTrailerScan />,
  },

  // Label Routes
  {
    name: <FormattedMessage id="sortation.name.labelPrint" defaultMessage="Choose Label Action" />,
    path: SortationPath.LABEL_PRINT,
    page: <LabelPrint />,
  },
  {
    name: <FormattedMessage id="sortation.name.shippingLabelDownload" defaultMessage="Shipping Label Download" />,
    path: SortationPath.SHIPPING_LABEL_DOWNLOAD,
    page: <ShippingLabelDownload />,
    previousPage: SortationPath.LABEL_PRINT,
  },
  {
    name: <FormattedMessage id="sortation.name.zebraLabelPrint" defaultMessage="Zebra Label Print" />,
    path: SortationPath.ZEBRA_LABEL_PRINT,
    page: <ZebraLabelPrint />,
  },
  {
    name: <FormattedMessage id="sortation.name.shippingLabel" defaultMessage="Shipping Label" />,
    path: SortationPath.SHIPPING_LABEL,
    page: <ShippingLabel />,
    previousPage: SortationPath.LABEL_PRINT,
  },
  {
    name: <FormattedMessage id="sortation.name.gaylordLabel" defaultMessage="Gaylord Label" />,
    path: SortationPath.GAYLORD_LABEL,
    page: <GaylordLabel />,
    previousPage: SortationPath.LABEL_PRINT,
  },
  {
    name: <FormattedMessage id="sortation.name.dockDoorLabel" defaultMessage="Dock Door Label" />,
    path: SortationPath.DOCK_DOOR_LABEL,
    page: <DockDoorLabel />,
    previousPage: SortationPath.LABEL_PRINT,
  },

  // Manifest Routes
  {
    name: <FormattedMessage id="sortation.name.manifests" defaultMessage="Manifests" />,
    path: SortationPath.MANIFESTS,
    page: <Manifests />,
  },
];
