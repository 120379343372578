import { ManifestError, ManifestProvider, ManifestWithAccountDetails } from "@deliverr/sortation-client";
import { initiateDownloadFromUrl } from "@deliverr/ui";
import { createSuccessNotification } from "@deliverr/ui-facility";
import { useCommonFlow } from "@deliverr/ui-facility/lib-facility/flow/useCommonFlow";
import { useCallback, useState } from "react";
import { useAsync } from "react-use";
import { useRecoilValue } from "recoil";
import { sortationIdState } from "sortation/base/sortationIdState";
import { useSortationClient } from "sortation/base/useSortationClient";
import { useSortationModal } from "sortation/components/SortationModal";
import { TOAST_AUTO_CLOSE } from "sortation/utils/toastAutoClose";
import { manifestMessageMap, ManifestModal } from "./modals";

export const useManifests = () => {
  const { addAutoCloseNotification, emitFlash } = useCommonFlow();
  const { showModal } = useSortationModal();
  const sortationId = useRecoilValue(sortationIdState);
  const sortationClient = useSortationClient();

  const [manifests, setManifests] = useState<ManifestWithAccountDetails[]>([]);
  const [manifestsCurrentlyDownloading, setManifestsCurrentlyDownloading] = useState<number[]>([]);

  const handleError = useCallback(
    (err) => {
      const errCode = err.message;

      let modal: ManifestModal;

      switch (errCode) {
        case ManifestError.FAILED_TO_GET_MANIFEST:
          modal = ManifestModal.FAILED_TO_GET_MANIFEST;
          break;
        default:
          modal = ManifestModal.UNKNOWN_ERROR;
          break;
      }

      emitFlash("DANGER");
      showModal(modal, manifestMessageMap[modal]);
    },
    [emitFlash, showModal]
  );

  // Initial data fetch
  const { loading: isLoading } = useAsync(async () => {
    try {
      if (!sortationId) {
        return;
      }

      const fetchedManifests = await sortationClient.getManifests(sortationId);
      setManifests(fetchedManifests);
    } catch (err) {
      handleError(err);
    }
  }, [sortationId]);

  const downloadManifest = useCallback(
    async (manifest: ManifestWithAccountDetails) => {
      if (manifest.manifestProvider === ManifestProvider.DHL) {
        // Add manifest to downloading list
        setManifestsCurrentlyDownloading((prev) => [...prev, manifest.id]);
        try {
          const url = await sortationClient.getDhlManifestUrl(manifest.providerAccountId, manifest.manifestId);
          initiateDownloadFromUrl(url, `${manifest.providerAccountId}_${manifest.manifestId}.pdf`);

          // Notify user that the manifest has been downloaded
          addAutoCloseNotification(createSuccessNotification(`Manifest ${manifest.id} downloaded`), TOAST_AUTO_CLOSE);

          // Remove manifest from downloading list
          setManifestsCurrentlyDownloading((prev) => prev.filter((manifestId) => manifestId !== manifest.id));
        } catch (err) {
          handleError(err);
        }
      }
    },
    [addAutoCloseNotification, handleError, sortationClient]
  );

  return {
    manifests,
    manifestsCurrentlyDownloading,
    isLoading,
    downloadManifest,
    sortationId,
  };
};
