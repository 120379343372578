import { isFeatureOn } from "@deliverr/ui-facility";
import { useRecoilValue } from "recoil";
import { SortationFeature } from "./SortationFeature";
import { sortationFeatureClientState } from "./sortationFeatureClientState";

/**
 * useSortationFeature
 * - A feature flag client hook for sortation feature flags
 *
 * @returns {Object} - An object containing properties in this format: isFeatureEnabled: isClientInitialized && isFeatureOn(SortationFeature.FEATURE_NAME)
 */
export const useSortationFeature = () => {
  const isClientInitialized = useRecoilValue(sortationFeatureClientState);

  return {
    isClientInitialized,
    isCustomsScanEnabled: isClientInitialized && isFeatureOn(SortationFeature.CUSTOMS_SCAN_ENABLED),
    isGaylordTypeEnabled: isClientInitialized && isFeatureOn(SortationFeature.GAYLORD_TYPE_ENABLED),
  };
};
