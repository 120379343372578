import { SortationCenterError } from "@deliverr/sortation-client";
import { connectedPrinterState, createDangerNotification, log, logError, logStart } from "@deliverr/ui-facility";
import { useCommonFlow } from "@deliverr/ui-facility/lib-facility/flow/useCommonFlow";
import { isNil } from "lodash/fp";
import { useState } from "react";
import { useIntl } from "react-intl";
import { useAsyncFn } from "react-use";
import { useRecoilValue } from "recoil";
import { desktopPrinterState } from "sortation/base/BrowserPrint";
import { sortationIdState } from "sortation/base/sortationIdState";
import { useSortationCenterError } from "sortation/base/useSortationCenterError";
import { useSortationClient } from "sortation/base/useSortationClient";
import { useSortationPrint } from "sortation/base/useSortationPrint";
import { TOAST_AUTO_CLOSE } from "sortation/utils/toastAutoClose";
import { GaylordLabelNumberOption, gaylordLabelOptions } from "./gaylordOptions";

const disableAutoReprintCommand = "^JZN";
const enableAutoReprintCommand = "^JZY";

export const useGaylordPrint = () => {
  const { formatMessage } = useIntl();
  const sortationId = useRecoilValue(sortationIdState);

  const sortationClient = useSortationClient();

  // Mobile Printing Recoil State
  const hasConnectedPrinter = useRecoilValue(connectedPrinterState);

  // Desktop Printing Recoil State
  const desktopPrinter = useRecoilValue(desktopPrinterState);

  const { addAutoCloseNotification } = useCommonFlow();
  const [selectedNumberOfLabels, setSelectedNumberOfLabels] = useState<GaylordLabelNumberOption>(
    gaylordLabelOptions[0]
  );
  const { print } = useSortationPrint();
  const { handleSortationCenterError } = useSortationCenterError();

  const handleNumberOfLabelsChange = (gaylordNumberOption: GaylordLabelNumberOption) => {
    setSelectedNumberOfLabels(gaylordNumberOption);
  };

  const [createGaylordState, createGaylords] = useAsyncFn(async () => {
    const ctx = logStart({ fn: "createGaylords", numberOfLabels: selectedNumberOfLabels.value });
    try {
      const gaylordData = await sortationClient.createGaylords(selectedNumberOfLabels.value, sortationId!);
      log(ctx, "Created gaylords");

      // Disable auto reprint command to avoid reprinting the same label multiple times
      await print(disableAutoReprintCommand);

      // Print each label individually while awaiting for a successful response
      for (const gaylord of gaylordData.sortationGaylords) {
        await print(gaylord.labelContents);
      }
    } catch (err) {
      logError(ctx, err);
      if (err.message === SortationCenterError.SORTATION_CENTER_NOT_FOUND) {
        handleSortationCenterError();
        return;
      }
      addAutoCloseNotification(
        createDangerNotification(
          formatMessage({
            id: "sortation.gaylordPrint.createGaylordError",
            defaultMessage: "Something went wrong, please try again.",
          })
        ),
        TOAST_AUTO_CLOSE
      );
    } finally {
      // Enable auto reprint command to allow for future reprints
      await print(enableAutoReprintCommand);
    }
  }, [selectedNumberOfLabels, sortationId]);

  return {
    selectedNumberOfLabels,
    handleNumberOfLabelsChange,
    createGaylords,
    createGaylordState,
    hasConnectedPrinter: !isNil(hasConnectedPrinter) || !isNil(desktopPrinter),
  };
};
