import { isZebraScanner } from "@deliverr/ui-facility";
import { MenuPageConfig } from "@deliverr/ui-facility/lib-facility/components/Menu/MenuConfig";
import { hubSortationCenters } from "@deliverr/ui-facility/lib-facility/hubs";
import { AUTOMATED_SORTATION_CENTERS } from "sortation/pages/DivertLaneScan/automatedSortationCenters";
import { SortationPath } from "./sortationPath";

export const sortationMenuConfig: Record<string, MenuPageConfig> = {
  MAIN: {
    items: [
      {
        icon: "scan",
        text: "Arrival Scan",
        path: SortationPath.ARRIVAL,
      },
      {
        icon: "dolly",
        text: "Gaylord Scan",
        path: SortationPath.OUTBOUND,
      },
      {
        icon: "dolly",
        text: "Divert Lane Scan",
        path: SortationPath.DIVERT_LANE,
        isHidden: (sortationId) => !(sortationId && AUTOMATED_SORTATION_CENTERS.includes(sortationId)),
      },
      {
        icon: "code-branch",
        text: "Hub App",
        isHidden: (sortationId) => !(sortationId && hubSortationCenters.includes(sortationId)),
        path: SortationPath.HUB_APP_REDIRECT,
      },
      {
        icon: "dolly",
        text: "Dock doors",
        path: SortationPath.DOCK_DOOR_SCAN_V2,
      },
      {
        icon: "dolly",
        text: "Left In Building",
        path: SortationPath.LEFT_IN_BUILDING_SCAN,
      },
      {
        icon: "dolly",
        text: "BOL/Trailer Scan",
        path: SortationPath.LOAD_TO_TRAILER_SCAN,
      },
      {
        icon: "print",
        text: "Print labels",
        path: SortationPath.LABEL_PRINT,
      },
      {
        icon: "scan",
        text: "Utility scans",
        path: SortationPath.UTILITY_SCAN,
      },
      {
        icon: "document",
        text: "Manifests",
        path: SortationPath.MANIFESTS,
        isHidden: isZebraScanner,
      },
    ],
  },
};
